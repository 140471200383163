import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { PanelComponent } from "lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { ViewListOfTasks } from "src/app/tasks/components/viewListOfTasksElement/viewListOfTasks.element.component";
import { ManageSchedule } from "src/app/tasks/services/manageSchedule";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "global-search-task-list-legacy",
   templateUrl: "./global-search-task-list-legacy.component.html",
   styleUrls: ["./global-search-task-list-legacy.component.scss"],
   standalone: true,
   imports: [PanelComponent, ViewListOfTasks],
})
export class GlobalSearchTaskListComponentLegacy implements OnInit {
   @Input() search?: string;
   @Input() taskIDs?: Array<number>;
   public searchHints: LimbleMap<number, string> = new LimbleMap();

   protected readonly columns: Array<{
      key: string;
      displayName: string;
      sortBy: string;
      columnWidth: number;
   }>;
   public tasks: Lookup<"checklistID", Task> = new Lookup("checklistID");

   private readonly manageTask = inject(ManageTask);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUser = inject(ManageUser);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageParts = inject(ManageParts);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.columns = [
         {
            key: "checklistName",
            displayName: this.lang().TaskName,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().DueDate,
            sortBy: "checklistDueDate",
            columnWidth: 2,
         },

         {
            key: "assignedTo",
            displayName: this.lang().AssignedTo,
            sortBy: "orderByAssigned",
            columnWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().CompletedDate,
            sortBy: "checklistCompletedDate",
            columnWidth: 2,
         },
         {
            key: "completedByStr",
            displayName: this.lang().CompletedBy,
            sortBy: "completedByStr",
            columnWidth: 2,
         },
      ];
   }

   public ngOnInit(): void {
      if (this.search === undefined) {
         throw new Error("required input `search` is not defined");
      }
      if (this.taskIDs === undefined) {
         throw new Error("required input `taskIDs` is not defined");
      }
      this.setTasks();
   }

   private setTasks(): void {
      assert(this.taskIDs !== undefined);
      for (const taskID of this.taskIDs) {
         const task = this.manageTask.getTaskLocalLookup(taskID);
         if (task === undefined) {
            continue;
         }
         this.tasks.set(task.checklistID, task);
      }
   }

   protected addSearchHintsForTasks(taskIDs: Array<number>) {
      const taskLookup: Lookup<"checklistID", Task> = new Lookup("checklistID");
      for (const taskID of taskIDs) {
         const task = this.manageTask.getTaskLocalLookup(taskID);
         if (task === undefined) {
            continue;
         }
         taskLookup.set(task.checklistID, task);
      }
      const filteredTasks = this.manageFilters.filterTasksToSearch(
         taskLookup,
         this.search ?? "",
         this.manageTask,
         this.manageLocation,
         this.manageUser,
         this.manageUtil,
         this.manageAsset,
         this.manageInvoice,
         this.manageParts,
         this.manageSchedule,
      );
      this.tasks = filteredTasks.tasks;
      this.searchHints = filteredTasks.searchHints;
   }
}
