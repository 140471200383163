@if (tasks.size > 0) {
   <div class="global-search-section">
      <lim-ui-panel [title]="lang().Tasks" [noSidePadding]="true">
         <view-list-of-tasks
            [taskIDs]="taskIDs"
            [columns]="columns"
            [tableInScrollablePage]="true"
            (tasksInViewChanged)="addSearchHintsForTasks($event.tasksInView)"
            [autoHeight]="true"
            [searchHints]="searchHints"
         />
      </lim-ui-panel>
   </div>
}
