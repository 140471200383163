import { AsyncPipe, NgStyle } from "@angular/common";
import { Component, Renderer2, computed, inject, type Signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RouterLink } from "@angular/router";
import Cookies from "js-cookie";
import {
   DropdownComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   MinimalIconButtonComponent,
   ModalService,
   TooltipDirective,
} from "lim-ui";
import { filter, take, type Observable } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ThemingService } from "src/app/settings/services/themingService";
import { FailedEmailBanner } from "src/app/shared/components/global/banners/failedEmailBannerElement/failedEmailBanner.element.component";
import { GettingStarted } from "src/app/shared/components/global/gettingStartedModal/gettingStarted.modal.component";
import { GlobalSearchComponent } from "src/app/shared/components/global/globalSearch/globalSearch.component";
import { ManageEmails } from "src/app/shared/services/manageEmails";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import type { SmtpSettings } from "src/app/shared/types/general.types";
import type { Timer } from "src/app/shared/utils/app.util";
import { PastDueBannerElementComponent } from "src/app/subscriptions/components/pastDueBanner/pastDueBanner.element.component";
import { TrialBanner } from "src/app/subscriptions/components/trialBannerElement/trialBanner.element.component";
import { ManageSubscription } from "src/app/subscriptions/services/manageSubscription";
import {
   ManageTrials,
   type TrialBannerOptions,
} from "src/app/subscriptions/services/manageTrials";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "app-header",
   templateUrl: "./app-header.component.html",
   styleUrls: ["./app-header.component.scss"],
   standalone: true,
   imports: [
      NgStyle,
      MinimalIconButtonComponent,
      PastDueBannerElementComponent,
      FailedEmailBanner,
      DropdownComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      TrialBanner,
      RouterLink,
      TooltipDirective,
      AsyncPipe,
   ],
})
export class AppHeaderComponent {
   public logoURL: Signal<string>;
   public logoURLDarkCSS;
   public useCustomLogo: boolean = false;
   public customLogoURL: string | undefined;
   protected usingCustomLogo: boolean = false;
   private customerID?: number;
   public crossOriginAnonymous: boolean;
   protected readonly lang = inject(ManageLang).lang;
   protected currentUser;
   public smtpSettings: SmtpSettings = {
      enabled: 0,
      itContactEmail: null,
      smtpServer: null,
      smtpUsername: null,
      smtpFromAddress: null,
      smtpFromName: null,
      smtpPassword: null,
      smtpPort: null,
      smtpSSLTLS: null,
      smtpSTARTTLS: null,
      emailsFailing: 0,
   };
   protected poweredBy: boolean = false;
   protected showPastDueReminder: boolean = false;
   private readonly manageSubscription = inject(ManageSubscription);
   private readonly manageUser = inject(ManageUser);
   public trialStatus: TrialBannerOptions | undefined;
   private readonly renderer = inject(Renderer2);
   protected isBasicPlan$: Observable<boolean>;
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   protected isCustomerActive: boolean = false;
   protected isManagerOrSuperUser: boolean = false;
   private readonly credService = inject(CredService);
   protected currentlyRefreshing$: Observable<boolean>;
   protected lastRefreshed$: Observable<string>;
   public currentRefreshTimer;
   public refreshIntervalDisplay: string = "Off";
   private readonly refreshService = inject(RefreshService);
   private refreshInterval?: Timer | undefined;
   private keepLoggedInInterval?: Timer | undefined;
   private readonly manageLogin = inject(ManageLogin);

   public constructor() {
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
      const whiteLabelService = inject(WhiteLabelService);
      const manageEmails = inject(ManageEmails);
      const manageTrials = inject(ManageTrials);
      const themingService = inject(ThemingService);
      this.logoURL = computed(() => {
         const theme = themingService.theme();
         return theme === "light"
            ? whiteLabelService.logoUrlDark()
            : whiteLabelService.logoUrl();
      });
      this.logoURLDarkCSS = whiteLabelService.logoCssDark();
      this.manageUser.currentUserChanges$
         .pipe(takeUntilDestroyed())
         .subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.customerID = currentUser.userInfo.customerID;
            this.setCustomLogo(currentUser.userInfo.customLogoFileName);
            this.usingCustomLogo = Boolean(currentUser.userInfo.customLogoFileName);
            this.poweredBy = whiteLabelService.shouldShowPoweredBy();
            this.initializeChargifySubscriptionState();
            this.isCustomerActive =
               this.currentUser.userInfo.customerActive == 1 ||
               this.currentUser.userInfo.userInternal == 1;
            this.isManagerOrSuperUser = this.getIsManagerOrSuperUser();
            if (this.currentUser?.userInfo?.featureCustomSmtp) {
               manageEmails.getSmtpSettings().then((answer) => {
                  if (!answer) return;
                  this.smtpSettings = answer;
               });
            }
         });
      this.lastRefreshed$ = this.refreshService.lastRefreshed();
      this.currentlyRefreshing$ = this.refreshService.stateOfRefresh();
      this.manageUser.currentUserInitialized$.subscribe(() => {
         const currentUser = this.manageUser.getCurrentUser();
         if (
            currentUser?.userInfo?.userUIPreferences?.currentRefreshTimer === undefined
         ) {
            this.currentRefreshTimer = 0;
            this.setRefreshIntervalDisplay();
         } else {
            this.setRefreshInterval(
               Number(currentUser.userInfo.userUIPreferences.currentRefreshTimer),
            );
         }
      });
      this.manageLogin.updateCustomLogo$.subscribe((newValue) => {
         this.usingCustomLogo = Boolean(newValue);
         this.setCustomLogo(newValue);
      });
      manageTrials.trialBanner$
         .pipe(takeUntilDestroyed())
         .subscribe((trialStatus: TrialBannerOptions) => {
            this.trialStatus = trialStatus;
         });
      manageTrials.checkForCurrentTrial();
      this.isBasicPlan$ = this.manageSubscription.isBasicPlan$;
   }

   protected showGlobalSearch(): void {
      const instance = this.modalService.open(GlobalSearchComponent);
      this.paramsService.params = {
         modalInstance: instance,
      };
   }

   protected openGettingStartedModal(): void {
      const date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      Cookies.set("gettingStarted", "true", { expires: date, path: "/" });
      setTimeout(() => {
         const instance = this.modalService.open(GettingStarted);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {},
         };
      }, 100);
   }

   protected openHelp(): void {
      window.open("https://help.limblecmms.com/en", "_blank");
   }

   protected handleRefreshClick(): void {
      this.currentlyRefreshing$
         .pipe(
            take(1),
            filter((currentlyRefreshing) => currentlyRefreshing === false),
         )
         .subscribe(() => {
            this.refreshService.refreshData({ force: true });
         });
   }

   protected setRefreshInterval(minutes) {
      if (this.manageUser.getCurrentUser() !== undefined) {
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.currentRefreshTimer =
            minutes; //sets for later retrival.
         this.manageUser.updateUserUIPreferences(); //updates DB with settings
      }

      this.currentRefreshTimer = minutes;

      if (minutes > 0) {
         //this.alertService.addAlert("Auto refresh timer set for <b>"+minutes+" minutes</b>",'success',1500);

         if (this.refreshInterval !== undefined) {
            clearInterval(this.refreshInterval);
         }
         if (this.keepLoggedInInterval !== undefined) {
            clearInterval(this.keepLoggedInInterval);
         }

         this.refreshInterval = setInterval(() => {
            if (!this.modalService.hasOpenModals()) {
               this.refreshService.refreshData();
            }
         }, minutes * 60000);

         //we need to checkLogin for several reasons, but mostly to reset php session information.  This allows them to stay logged in.
         //we can probably depreciate this once we go to db session storage.
         this.keepLoggedInInterval = setInterval(() => {
            this.manageLogin.checkLogin();
         }, 10 * 60000);
      } else {
         //this.alertService.addAlert("Auto Refresh is now disabled",'success',1500);
         if (this.refreshInterval !== undefined) {
            clearInterval(this.refreshInterval);
         }
         if (this.keepLoggedInInterval !== undefined) {
            clearInterval(this.keepLoggedInInterval);
         }
      }
      this.setRefreshIntervalDisplay();
   }

   private setCustomLogo(fileName: string | null) {
      if (!fileName) {
         this.useCustomLogo = false;
         this.customLogoURL = "";
         return;
      }
      this.customLogoURL = `viewFile.php?f=upload-${this.customerID}/customLogo/${fileName}`;
      this.useCustomLogo = true;
   }

   private initializeChargifySubscriptionState(): void {
      this.manageSubscription.getChargifyData().then((response: any) => {
         const currentUser = this.manageUser.getCurrentUser();
         this.showPastDueReminder =
            response?.data?.subscription?.state === "past_due" &&
            (currentUser?.userInfo?.subscriptionType === "normal" ||
               currentUser?.userInfo?.subscriptionType === "distributor");
      });
   }

   private getIsManagerOrSuperUser(): boolean {
      // 30 means superUser, and if they can add users (97) then they are at least a manager
      if (
         !this.credService.checkCredAnywhere(this.credService.Permissions.AddUsers) &&
         !this.credService.checkCredGlobal(this.credService.Permissions.ManageRoles)
      ) {
         return false;
      }

      return true;
   }

   private setRefreshIntervalDisplay() {
      if (this.currentRefreshTimer === 0) {
         this.refreshIntervalDisplay = `${this.lang()?.currentTimer}: ${this.lang()?.off}`;
         return;
      }
      this.refreshIntervalDisplay = `${this.lang()?.currentTimer}: ${this.currentRefreshTimer} ${this.lang()?.min}`;
   }
}
