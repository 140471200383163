<lim-ui-modal [showWatermark]="true" [extraLarge]="true">
   <lim-ui-modal-header>
      <div class="global-search-controls">
         <div class="header-and-icon lim-ui-show-on-mobile">
            <h1 class="global-search-title">{{ lang().GlobalSearch }}</h1>
            <lim-ui-minimal-icon-button
               icon="xmarkLargeRegular"
               iconSize="medium"
               (click)="close()"
            />
         </div>
         @if (!dataInitializing) {
            <div class="main">
               <div
                  class="global-search-bar-container"
                  [ngClass]="{ 'full-width': true }"
               >
                  <div class="global-search-input-group">
                     <label for="global-search-input" class="global-search-icon">
                        <lim-ui-icon
                           class="icon-button"
                           icon="magnifyingGlass"
                           iconSize="small"
                        />
                     </label>
                     <input
                        id="global-search-input"
                        type="textbox"
                        ngDefaultControl
                        [placeholder]="isMobile ? lang().Search : lang().GlobalSearch"
                        [(ngModel)]="searchStringInput"
                        (keyup.enter)="submitSearch()"
                     />
                  </div>

                  @if (searchStringInput?.length) {
                     <span class="global-clear-search" (click)="clearSearch()">
                        Clear
                     </span>
                  }
               </div>

               <button
                  class="search-button"
                  [disabled]="!canSearch(searchStringInput)"
                  (click)="submitSearch()"
               >
                  <lim-ui-icon icon="magnifyingGlass" iconSize="small" />
               </button>
            </div>
         }

         @if (!dataInitializing) {
            <div class="entity-settings">
               @for (entity of searchEntities; track entity) {
                  <div class="entity-setting">
                     <lim-ui-checkbox
                        [label]="getEntityLabel(entity)"
                        (modelChange)="checkboxChangeHandler(entity)"
                        [model]="getEntityChecked(entity)"
                     />
                  </div>
               }

               <div class="global-search-entity-controls">
                  <a (click)="checkAll()">
                     {{ lang().SelectAll }}
                  </a>
                  <span>|</span>
                  <a (click)="uncheckAll()">
                     {{ lang().DeselectAll }}
                  </a>
               </div>
            </div>
         }

         @if (isLoading()) {
            <div>
               <div class="loading-icon">
                  <!-- We have to use a .gif here instead of the svg animation because our task search takes too long. It uses the frontend's thread and freezes the animation. 😭-->
                  <img src="assets/img/loading-animation-clear-background.gif" />
               </div>
            </div>
         }
      </div>
   </lim-ui-modal-header>
   <lim-ui-modal-body>
      @if (dataInitializing) {
         <div>
            <div class="loading-icon data-initializing-section">
               <lim-ui-loading-animation />
               <div>{{ lang()?.PleaseWaitWhileTheAppFinishesStartingUp }}</div>
            </div>
         </div>
      }
      @if (!dataInitializing) {
         @if (isEmpty()) {
            <no-search-results />
         }
         @if (isJitTasksEnabled) {
            <global-search-task-list
               [searchInput]="search ?? ''"
               [showTasksChecked]="this.settings['tasks']"
               (requestState)="handleTaskSearchRequestStateChange($event)"
            />
         }
         @if (!isLoading()) {
            @if (!isJitTasksEnabled) {
               @if (responseIDs && search) {
                  <global-search-task-list-legacy
                     [search]="search"
                     [taskIDs]="responseIDs.tasks"
                  />
               }
            }
            @if (showPMsList() && responseIDs && search) {
               <global-search-pm-list [search]="search" />
            }

            @if (responseIDs && search) {
               <global-search-asset-list
                  [search]="search"
                  [assetIDs]="responseIDs.assets"
               />
            }
            @if (responseIDs && search) {
               <global-search-part-list [search]="search" [partIDs]="responseIDs.parts" />
            }
            @if (responseIDs && search) {
               <global-search-vendor-list
                  [search]="search"
                  [vendorIDs]="responseIDs.vendors"
               />
            }
            @if (responseIDs && search) {
               <global-search-po-list [search]="search" [poIDs]="responseIDs.pos" />
            }
         }
      }
   </lim-ui-modal-body>
</lim-ui-modal>
